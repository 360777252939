import appStrings from '@/app/utility/string.utility';
export default {
  name: 'assignQuery',
  components: {},
  props: ['assignQueryDetails'],
  data() {
    return {
      loader: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      unsubscribe: null,
      editMode: false,
      showValueSetModal: false,
      assignQueryData: [
        {
          query_assign_mst_line_id: 0,
          assignee_type_vset: null,
          assignee_type_vset_meaning: null,
          assignee_id: null,
          assignee_name: null,
          primary_flag: false
        }
      ],
      assignQueryFields: [
        {
          key: 'assignee_type_vset_meaning',
          label: 'Assignee Type'
        },
        {
          key: 'assignee_name'
        },
        {
          key: 'primary_flag',
          label: 'Primary',
          stickyColumn: true
        },
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        }
      ]
    };
  },
  mounted() {
    if (this.assignQueryDetails) {
      this.getAssignQueryDtlById();
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditCustomerAssignCategory();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getAssignQueryDtlById() {
      this.loader = true;
      this.$store
        .dispatch(
          'realManageAdmin/getAssignQueryDtlById',
          this.assignQueryDetails.query_assign_mst_dtl_id
        )
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.assignQueryData = resp.data.data;
            this.totalRows = resp.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditCustomerAssignCategory() {
      const queryDetails = this.assignQueryData.map(elem => {
        return {
          query_assign_mst_line_id: elem.query_assign_mst_line_id
            ? elem.query_assign_mst_line_id
            : 0,
          assignee_type_vset: elem.assignee_type_vset,
          assignee_id: elem.assignee_id,
          primary_flag: elem.primary_flag
        };
      });
      const payload = {
        query_assign_mst_dtl_id: this.assignQueryDetails.query_assign_mst_dtl_id
          ? this.assignQueryDetails.query_assign_mst_dtl_id
          : 0,
        query_assign_mst_line: queryDetails ? queryDetails : null
      };
      this.loader = true;
      this.$store
        .dispatch('realManageAdmin/addEditCustomerAssignCategory', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 201) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    addNewRow() {
      if (this.editMode) {
        this.assignQueryData.push({
          query_assign_mst_line_id: 0,
          assignee_type_vset: null,
          assignee_type_vset_meaning: null,
          assignee_id: null,
          assignee_name: null,
          primary_flag: false
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.assignQueryData.splice(index, 1);
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.selectedIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.ASSIGNEE_TYPE) {
        this.assignQueryData[this.selectedIndex].assignee_type_vset_meaning =
          item.value_meaning;
        this.assignQueryData[this.selectedIndex].assignee_type_vset =
          item.value_code;

        this.assignQueryData[this.selectedIndex].assignee_name = null;
        this.assignQueryData[this.selectedIndex].assignee_id = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CC_EMPLOYEE) {
        this.assignQueryData[this.selectedIndex].assignee_name =
          item.value_code;
        this.assignQueryData[this.selectedIndex].assignee_id =
          item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CC_GROUP) {
        this.assignQueryData[this.selectedIndex].assignee_name =
          item.value_code;
        this.assignQueryData[this.selectedIndex].assignee_id =
          item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CC_DEPARTMENT) {
        this.assignQueryData[this.selectedIndex].assignee_name =
          item.value_code;
        this.assignQueryData[this.selectedIndex].assignee_id =
          item.value_set_dtl_id;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    chooseValueSet(valueSet, index) {
      if (valueSet === 'Employee') {
        this.openValueSetModal('CC_EMPLOYEE', index);
      } else if (valueSet === 'Group') {
        this.openValueSetModal('CC_GROUP', index);
      } else if (valueSet === 'Department') {
        this.openValueSetModal('CC_DEPARTMENT', index);
      } else {
        alert('Please Select Assignee Type');
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
