import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import addEditQueryAssignment from './addEditQueryAssignment';
import Modules from '../../admin/responsibility/searchModule';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'queryAssignment',
  components: {
    addEditQueryAssignment,
    Modules
  },
  validations: {
    moduleName: { required }
  },
  props: {},
  data() {
    return {
      moduleName: null,
      moduleId: null,
      entityType: {
        value: null,
        text: null
      },
      category: {
        value: null,
        text: null
      },
      subCategory: {
        text: null,
        value: null
      },
      subSubCategory: {
        text: null,
        value: null
      },
      showValueSetModal: false,
      parent_value_set_id: null,
      vsetCode: null,
      showQueryAssignmentModal: false,
      payload: null,
      unsubscribe: null,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      showModules: false,
      queryAssignDetails: null,
      queryAssignmentData: [],
      queryAssignmentfields: [
        {
          key: 'module_name'
        },
        {
          key: 'project_name'
        },
        {
          key: 'entity_type_mean',
          label: 'Entity Type'
        },
        {
          key: 'entity_name'
        },
        {
          key: 'query_cat_mean',
          label: 'Category'
        },
        {
          key: 'query_sub_cat_mean',
          label: 'Sub Category'
        },
        {
          key: 'query_sub_sub_cat_mean',
          label: 'Sub-Sub Category'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showQueryAssignmentModal = true;
          this.queryAssignDetails = null;
        }
      }
    });
  },
  methods: {
    getQueryAssignmentGridList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          module_id: this.moduleId,
          entity_type_vset: this.entityType.value,
          query_cat_vset: this.category.value,
          query_sub_cat_vset: this.subCategory.value,
          query_sub_sub_cat_vset: this.subSubCategory.value
        };
        this.loader = true;
        this.$store
          .dispatch('realManageAdmin/getQueryAssignmentGridList', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.queryAssignmentData = resp.data.data.page;
              this.totalRows = resp.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    selectedModule(item) {
      this.moduleName = item.module_name;
      this.moduleId = item.module_id;
      this.showModules = false;
    },
    clearModule() {
      this.moduleId = null;
      this.moduleName = null;
      this.queryAssignmentData = [];
      this.entityType = {
        value: null,
        text: null
      };
      this.category = {
        value: null,
        text: null
      };
      this.subCategory = {
        text: null,
        value: null
      };
      this.subSubCategory = {
        text: null,
        value: null
      };
      this.totalRows = null;
      this.currentPage = 1;
    },
    showHideModal(flag) {
      this.showModules = flag;
    },
    openValueSetModal(vsetCode) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE.CC_SUB_SUB_CAT) {
        this.parent_value_set_id = this.subCategoryId;
      } else {
        this.parent_value_set_id = null;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.CC_ENTITY_TYPE) {
        this.entityType = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CC_CAT) {
        this.category = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CC_SUB_CAT) {
        this.subCategory = {
          value: item.value_code,
          text: item.value_meaning
        };
        this.subCategoryId = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CC_SUB_SUB_CAT) {
        this.subSubCategory = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
      this.showValueSetModal = false;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    showHideaddEditqueryAssignment(flag) {
      this.showQueryAssignmentModal = flag;
    },
    rowSelected(items) {
      this.showHideaddEditqueryAssignment(true);
      this.queryAssignDetails = items;
    }
  }
};
