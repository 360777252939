import assignQuery from '../addEditQueryAssignment/assignQuery';
import ModulesList from '../../../admin/responsibility/searchModule';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'addEditQueryAssignment',

  components: {
    assignQuery,
    ModulesList
  },
  props: ['queryAssignDetails'],
  data() {
    return {
      loader: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      unsubscribe: null,
      editMode: false,
      depVsetParam: null,
      payload: null,
      tab: null,
      currentPage: 1,
      showModal: false,
      totalRows: null,
      selectedIndex: null,
      showAssignModal: false,
      showValueSetModal: false,
      assignQueryDetails: null,
      selectedModule: {
        name: null,
        id: null
      },
      project: {
        value: null,
        text: null
      },
      entityType: {
        value: null,
        text: null
      },
      entityAssigned: {
        value: null,
        text: null
      },
      category: {
        value: null,
        text: null
      },
      subCategory: {
        value: null,
        text: null
      },
      subSubCategory: {
        value: null,
        text: null
      },
      assignmentHdrDetails: {
        module: null,
        entityAssigned: null,
        entity: null,
        querySub: null,
        createdBy: null,
        creationDate: null
      },
      queryAssignmentData: [
        {
          escalation_level: 0,
          tat: null,
          uom_meaning: null,
          uom_vset: null,
          fms_query_assign_mst_hdr_id: null,
          fms_query_assign_mst_dtl_id: 0,
          assign: false
        }
      ],
      queryAssignmentFields: [
        {
          key: 'escalation_level'
        },
        {
          key: 'tat',
          label: 'TAT'
        },
        {
          key: 'uom_meaning',
          label: 'UOM'
        },
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'assign',
          stickyColumn: true
        }
      ],
      showAssignQueryModal: false,
      entityTypeValueset: [],
      tempArr: null
    };
  },
  mounted() {
    if (this.queryAssignDetails) {
      this.getQueryAssignmentHdrDetailsById();
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode && !this.showAssignModal) {
          this.addEditCustomerAssignQuery();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getQueryAssignmentHdrDetailsById() {
      this.loader = true;
      this.$store
        .dispatch(
          'realManageAdmin/getQueryAssignmentHdrDetailsById',
          this.queryAssignDetails.query_assign_mst_hdr_id
        )
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.assignmentHdrDetails = result;
            this.selectedModule.name = result.module_name;
            this.project.text = result.project_name;
            this.entityType.text = result.entity_type_mean;
            this.entityAssigned.text = result.entity_name;
            this.category.text = result.query_cat_mean;
            this.subCategory.text = result.query_sub_cat_mean;
            this.subSubCategory.text = result.query_sub_sub_cat_mean;
            if (
              result.query_assign_mst_details &&
              result.query_assign_mst_details.length > 0
            ) {
              this.queryAssignmentData = result.query_assign_mst_details;
              this.queryAssignmentData.map(data => {
                data.assign = true;
                return data;
              });
            }
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    addEditCustomerAssignQuery() {
      const queryDetails = this.queryAssignmentData.map(elem => {
        return {
          query_assign_mst_dtl_id: elem.query_assign_mst_dtl_id
            ? elem.query_assign_mst_dtl_id
            : 0,
          escalation_level: elem.escalation_level,
          tat: elem.tat,
          uom_vset: elem.uom_vset
        };
      });
      const payload = {
        query_assign_mst_hdr_id: this.queryAssignDetails.query_assign_mst_hdr_id
          ? this.queryAssignDetails.query_assign_mst_hdr_id
          : 0,
        module_id: this.selectedModule.id,
        entity_type_vset: this.entityType.value,
        entity_id: this.entityAssigned.value,
        query_cat_vset: this.category.value,
        query_sub_cat_vset: this.subCategory.value,
        query_sub_sub_cat_vset: this.subSubCategory.value,
        query_assign_mst_details: queryDetails ? queryDetails : null
      };
      this.loader = true;
      this.$store
        .dispatch('realManageAdmin/addEditCustomerAssignQuery', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 201) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.taskCreationGrid = response.data.data;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    addNewRow(index) {
      this.rowIndex = index;
      if (this.editMode) {
        this.queryAssignmentData.push({
          uom_meaning: null,
          query_assign_mst_hdr_id: null,
          uom_vset: null,
          tat: null,
          query_assign_mst_dtl_id: 0,
          escalation_level:
            this.queryAssignmentData[index].escalation_level + 1,
          assign: false
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.queryAssignmentData.splice(index, 1);
      }
    },
    validate() {
      this.tempArr = this.queryAssignmentData;
      const max = this.tempArr[0].escalation_level;
      for (let i = 0; i < this.tempArr.length; i++) {
        if (this.tempArr[i].escalation_level > max) {
          this.max = this.tempArr[i];
        }
      }
    },
    openAssignModal(flag, item) {
      this.showAssignModal = flag;
      this.assignQueryDetails = item;
    },
    getValuesetNameForEntity(moduleId) {
      this.loader = true;
      this.$store
        .dispatch(
          'realManageAdmin/getModuleBasedValuesetNameForEntity',
          moduleId
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.entityTypeValueset = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getCustomerQueryValueSetList(valueParams) {
      if (this.selectedModule.id) {
        const payload = {
          moduleId: this.selectedModule.id,
          catType: { valueset_type: valueParams }
        };
        this.loader = true;
        this.$store
          .dispatch('realManageAdmin/getCustomerQueryValueSet', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.openValueSetModal(response.data.data);
            }
          })
          .catch(() => {
            this.loader = false;
          });
      } else {
        alert('Please Select the Module First');
      }
    },
    opendDependentUom(vsetCode, index) {
      this.selectedIndex = index;
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          this.uomClassObj = null;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === 'TIME'
            );
            if (findTimeObj) {
              this.uomClassObj = findTimeObj;
              this.openValueSetModal('UOM');
            }
          }
        });
    },
    openModuleBasedComponent(component) {
      if (this.selectedModule.name === 'FMS') {
        switch (component) {
          case 'sector':
            this.openValueSetModal(appStrings.VALUESETTYPE.NFA_FMS_SECTOR);
            break;
          case 'pocket':
            this.openValueSetModal(appStrings.VALUESETTYPE.NFA_FMS_POCKET);
            break;
          case 'project':
            this.openValueSetModal(appStrings.VALUESETTYPE.FMS_PROJECT);
            break;
          case 'tower':
            this.openValueSetModal(appStrings.VALUESETTYPE.NFA_FMS_TOWER);
            break;
          case 'floor':
            this.openValueSetModal(appStrings.VALUESETTYPE.NFA_FMS_FLOOR);
            break;
          case 'unit':
            this.openValueSetModal(appStrings.VALUESETTYPE.NFA_FMS_UNIT);
            break;
          case 'subUnit':
            this.openValueSetModal(appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT);
            break;
        }
      } else if (this.selectedModule.name === 'Lease Management') {
        switch (component) {
          case 'project':
            this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_PROJECT_LIST);
            break;
          case 'tower':
            this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_TOWER_LIST);
            break;
          case 'floor':
            this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_FLOOR_LIST);
            break;
          case 'unit':
            this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_UNIT_LIST);
            break;
        }
      } else {
        alert('Please Select the Module First');
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      if (this.uomClassObj) {
        this.parent_value_set_id = this.uomClassObj.value_set_dtl_id;
        this.uomClassObj = null;
      } else {
        this.parent_value_set_id = null;
      }
      if (
        this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_TOWER ||
        this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_FLOOR ||
        this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_UNIT ||
        this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT
      ) {
        this.depVsetParam = this.project.value;
      }
      if (this.vsetCode === appStrings.VALUESETTYPE.CC_SUB_SUB_CAT) {
        this.parent_value_set_id = this.subCategoryId;
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.CC_ENTITY_TYPE) {
        this.entityType.text = item.value_meaning;
        this.entityType.value = item.value_code;
        this.entityAssigned.text = null;
        this.entityAssigned.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CC_CAT) {
        this.category.text = item.value_meaning;
        this.category.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CC_SUB_CAT) {
        this.subCategory.text = item.value_meaning;
        this.subCategory.value = item.value_code;
        this.subCategoryId = item.value_set_dtl_id;
        this.subSubCategory.text = null;
        this.subSubCategory.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CC_SUB_SUB_CAT) {
        this.subSubCategory.text = item.value_meaning;
        this.subSubCategory.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CC_ENTITY_TYPE) {
        this.entityType.text = item.value_meaning;
        this.entityType.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_SECTOR) {
        this.sector.text = item.value_code;
        this.sector.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_POCKET) {
        this.pocket.text = item.value_code;
        this.pocket.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.project.text = item.value_code;
        this.project.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_TOWER) {
        this.entityAssigned.text = item.value_code;
        this.entityAssigned.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_FLOOR) {
        this.entityAssigned.text = item.value_code;
        this.entityAssigned.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_UNIT) {
        this.entityAssigned.text = item.value_code;
        this.entityAssigned.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT) {
        this.entityAssigned.text = item.value_code;
        this.entityAssigned.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.project.text = item.value_code;
        this.project.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
        this.tower.text = item.value_code;
        this.tower.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST) {
        this.floor.text = item.value_code;
        this.floor.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_LIST) {
        this.unit.text = item.value_code;
        this.unit.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.UOM) {
        this.queryAssignmentData[this.selectedIndex].uom_meaning =
          item.value_meaning;
        this.queryAssignmentData[this.selectedIndex].uom_vset = item.value_code;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    setChildName(tab) {
      //method for child modal name
      if (tab === 'ModulesList') {
        return (this.childName = 'Modules List');
      }
    },
    openShowModal(flag, component) {
      this.showModal = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    selectedModuleData(module) {
      this.selectedModule.name = module.module_name;
      this.selectedModule.id = module.module_id;
      this.showModal = false;
      this.getValuesetNameForEntity(this.selectedModule.id);
    },
    openEntity(entityType) {
      if (entityType === 'Tower3') {
        this.openValueSetModal(appStrings.VALUESETTYPE.NFA_FMS_TOWER);
      } else if (entityType === 'FETFloor') {
        this.openValueSetModal(appStrings.VALUESETTYPE.NFA_FMS_FLOOR);
      } else if (entityType === 'Unit') {
        this.openValueSetModal(appStrings.VALUESETTYPE.NFA_FMS_UNIT);
      } else if (entityType === 'SubUnit2') {
        this.openValueSetModal(appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT);
      } else {
        alert('Please Select the Entity Type');
      }
    }
    // validateEscalation(value, index) {
    //   this.queryAssignmentData.forEach((e, i, a) => {
    //     if (index !== i) {
    //       if (value === e.escalation_level) {
    //         a[index].escalation_level = null;
    //         return alert('Escalation Level already exist!');
    //       }
    //     }
    //   });
    // }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
